<template>
  <div>
    <!-- float avatar -->
    <div class="relative d-flex justify-center align-center">
      <v-avatar
        class="absolute"
        style="margin-bottom: -64px; z-index: 1"
        size="80"
        tile
      >
        <v-img :src="league.image" :aspect-ratio="4 / 4" />
      </v-avatar>
    </div>

    <!-- card -->
    <v-card color="surface" class="rounded-lg">
      <!-- image -->
      <v-img :src="league.image" class="rounded-t-lg" :aspect-ratio="4 / 3">
        <div class="d-flex flex-column fill-height cover">
          <div class="d-flex justify-space-between mt-2">
            <!-- game -->
            <v-sheet color="white px-2 py-1 rounded-r-lg">
              <span class="black--text text-caption font-weight-bold">
                {{ league.game.name }}
              </span>
            </v-sheet>

            <!-- modality -->
            <v-sheet color="accent px-2 py-1 rounded-l-lg">
              <v-icon left>mdi-account-group-outline</v-icon>
              <span class="overtext--text text-caption font-weight-bold">
                x{{ league.x }}
              </span>
            </v-sheet>
          </div>

          <div
            class="d-flex flex-column fill-height align-center justify-center"
          >
            <!-- name -->
            <div class="d-flex text-center justify-center align-center mb-2">
              <span
                class="text-h6 text-sm-h5 text-lg-h6 text-xl-h5 font-weight-bold"
              >
                <span class="saira">{{ league.name }}</span>
              </span>
            </div>

            <!--Status-->
            <div v-if="!!league.status">
              <v-chip
                :color="handleStatus(league.status.value)"
                :outlined="league.status.value !== 0"
                label
              >
                {{ league.status.text }}
              </v-chip>
            </div>
          </div>
        </div>
      </v-img>

      <!-- content -->
      <section v-if="!!league.status" class="px-2 py-4">
        <!-- dates -->
        <div class="mb-4 px-2">
          <div class="d-flex">
            <v-icon left>mdi-calendar</v-icon>

            <span class="text-h6">
              <span class="saira">
                {{ league.status.next }}
              </span>
            </span>
          </div>

          <span class="text-body-1">
            <span class="saira">
              {{ formatDate(league.status.date) }}
            </span>
          </span>
        </div>

        <!-- actions -->
        <v-row no-gutters>
          <v-col v-if="league.status.value !== 2" cols="5">
            <!-- subscribed -->
            <v-btn
              v-if="subscribed"
              class="rounded-r-0 rounded-l-lg text-none"
              color="primary"
              block
              :to="`/team-details/${subscribed.id}`"
            >
              <v-icon left>mdi-check</v-icon> Inscrito
            </v-btn>

            <!-- subscribe -->
            <v-btn
              v-else
              class="rounded-r-0 rounded-l-lg text-none"
              color="primary"
              :disabled="league.status.value !== 0"
              block
              @click="subscribe()"
            >
              Participar
            </v-btn>
          </v-col>

          <v-col
            :cols="league.status.value !== 2 ? '7' : '12'"
            :class="league.status.value !== 2 ? 'pl-2' : ''"
          >
            <!-- informations -->
            <v-btn
              class="rounded-l-0 rounded-r-lg text-none"
              color="accent lighten-1"
              :to="`/league-details/${league.id}`"
              block
            >
              <v-icon left>mdi-plus-box</v-icon>
              Informações
            </v-btn>
          </v-col>
        </v-row>
      </section>
    </v-card>

    <ProofDialog ref="proofDialog" @success="subscribe(true)" />

    <JoinLeagueConfirmation
      ref="confirm"
      :item="league"
      @confirm="soloSubscribe()"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createTeam } from "@/services/teams";
import { formatDate } from "@/utils";
import JoinLeagueConfirmation from "./JoinLeagueConfirmation.vue";
import ProofDialog from "./ProofDialog.vue";

export default {
  props: {
    league: {
      type: Object,
      required: true,
    },
  },

  components: {
    JoinLeagueConfirmation,
    ProofDialog,
  },

  computed: {
    ...mapState(["localUser"]),

    subscribed() {
      if (!this.$store.getters.authenticated) return false;

      return this.league.teams.find((e) =>
        e.team_players.find((j) => j.user.id === this.localUser.id)
      );
    },
  },

  methods: {
    async subscribe(force = false) {
      if (!this.$store.getters.authenticated) {
        const url = `${
          this.$store.getters.authURL
        }/auth?callback_url=${encodeURIComponent(
          this.$store.getters.appURL + "/home"
        )}`;

        return window.open(url, "_self");
      }

      if (this.localUser.schoolCertificate && !force)
        this.$refs.proofDialog.handleDialog();
      else {
        if (this.league.x < 2) return this.handleRequestConfirm(this.league);
        this.$router.push({ path: `/teams/new?leagueId=${this.league.id}` });
      }
    },

    handleRequestConfirm() {
      const element = this.$refs.confirm;
      if (!element) return;

      element.dialog = true;
    },

    soloSubscribe() {
      const payload = {
        tag: "",
        name: this.localUser.nickname,
        logo:
          this.localUser.image ||
          "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team1.svg",
        leagueId: this.league.id,
      };

      createTeam(payload)
        .then((data) => {
          this.$router.push({ path: `/team-details/${data.id}` });
        })
        .catch(() =>
          this.displayAlert(
            "Falha ao tentar participar da liga, entre em contato com o administrador.",
            1
          )
        );
    },

    handleStatus(value) {
      switch (String(value)) {
        case "0":
          return "green";
        case "1":
          return "amber";
        case "3":
          return "red";
        default:
          return "gray";
      }
    },

    formatDate,
  },
};
</script>

<style lang="scss" scoped>
.cover {
  background-color: rgba(0, 0, 0, 0.85);
}
</style>
